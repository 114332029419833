@import 'base_footer';

footer {
    .container {
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-template-rows: repeat(5, 1fr);
        grid-column-gap: .5rem;
        padding: 3rem 0;

        a, 
        p {
            img {
                transition: .3s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        a {
            transition: .3s;

            &:hover {
                color: $main_color;
            }
        }

        .logo {
            display: block;
            width: 80%;
            grid-column: 1/2;
            grid-row: 1/4;
            margin: 0 auto;
        }

        .street {
            grid-column: 2/4;
            grid-row: 1/3;
        }

        .phones {
            grid-column: 2/4;
            grid-row: 3/4;
        }

        .time {
            grid-column: 2/4;
            grid-row: 4/5;
        }

        .social-icons {
            grid-column: 1/2;
            grid-row: 5/6;
            justify-content: space-evenly;
        }

        .messangers {
            grid-column: 1/4;
            grid-row: 5/6;
            justify-content: space-around;
        }

        .map {
            grid-column: 4/7;
            grid-row: 1/6;
        }
    }
}
