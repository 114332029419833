@import 'mixins';
@import '../vendor/leaflet/leaflet.css';

@font-face {
    font-family: 'Museo';
    src: local('Museo Bold'), 
         local('Museo-Bold'),
         url('../vendor/fonts/MuseoSansBold.OTF');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: local('Museo Regular'), 
         local('Museo-Regular'),
         url('../vendor/fonts/MuseoSansRegular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Museo';
    src: local('Museo Light'), 
         local('Museo-Light'),
         url('../vendor/fonts/MuseoSansLight.ttf');
    font-weight: 300;
    font-style: normal;
}

$screen-sizes: (2560: 18, 1920: 16, 1600: 14, 1400: 12, 1366: 10, 768: 12);

@each $size, $font in $screen-sizes {
    @media screen and (max-width: #{$size}px) {
        html {
            font-size: #{$font}px;
        }
    }
}

@media screen and (max-width: 768px) {
    .stub-mob {
        display: block;
    }
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-family: Museo;
    line-height: initial;
    font-weight: normal;
    color: #000;
    outline: none;
}

h1, h2, h3, h4 {
    margin: 0;
    font-family: Museo;
    font-weight: 400;
    text-shadow: 4px 4px 4px rgba(0,0,0,.3);
}

h1 {
    font-size: 3rem;
}

h2 {
    font-size: 2.5rem;
}

h3 {
    font-size: 2rem;
}

h4 {
    font-size: 1.5rem;
}

figure {
    margin: 0;
}

p {
    margin: 0;
    font-family: Museo;
    font-weight: 500;
}

a {
    text-decoration: none;
    color: #fff;
    font-size: 1rem;
    font-family: Museo;
    font-weight: 500;

    &:focus,
    &:active {
        outline: none;
    }
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

a:focus,
button:focus {
    outline: 0;
}

p {
    margin: 1rem 0;
    font-size: 1rem;
}

img {
    min-width: auto;
}

.button {
    display: table;
    padding: .8rem 2rem;
    color: #fff;
    font-size: 1.2rem;
    border: 1px solid $main_color;
    border-radius: 1.5rem;
    transition: .3s;

    &:hover {
        background: $main_color;
    }
}

.record-button {
    position: fixed;
    bottom: 3rem;
    right: 5rem;
    width: 4.1rem;
    padding: 1rem;
    border-radius: 3rem;
    background: $main_color;
    transition: .5s;
    overflow: hidden;
    cursor: pointer;
    z-index: 7;

    img {
        display: block;
        width: 2rem;
        transition: .5s;
    }

    span {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 4rem;
        display: flex;
        align-items: center;
    }

    &:hover,
    &.active {
        width: 14rem;

        img {
            transform: rotate(-360deg);
        }
    }
}

.record-form {
    @include abs-position;
    @include hidden;
    
    position: fixed;
    display: flex;
    align-items: center;
    background: rgba(0, 0, 0, .7);
    z-index: 5;

    &.active {
        @include visible;
    }

    .container {
        width: fit-content;
        padding: 0;

        h4 {
            margin-bottom: 2rem;
            font-size: 2rem;
            color: #fff;
            text-align: center;
            text-shadow: 4px 4px 4px rgba(255,255,255,.3);
        }

        .buttons {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
        }

        .close {
            position: absolute;
            top: -4rem;
            right: -4rem;
            width: 3.5rem;
            height: 3.5rem;
            border: 1px solid $main_color;
            border-radius: 50%;
            transition: .3s;
            cursor: pointer;

            &:before,
            &:after {
                @include pseudo-base;

                width: 2px;
                height: 60%;
                border-radius: 3rem;
                background: #fff;
            }

            &:before {
                transform: rotate(45deg);
            }

            &:after {
                transform: rotate(-45deg);
            }

            &:hover {
                transform: scale(1.1) rotate(180deg);
            }
        }
    }
}
