$main_color: #40A7AC;

@keyframes vertical-line-slide {
    0% {
        top: 0;
        height: 0;
    }
    40% {
        top: 0;
        height: 100%;
    }
    80% {
        top: 100%;
        height: 0;
    }
}

@keyframes vertical-line-slide-90 {
    0% {
        top: 5%;
        height: 0;
    }
    40% {
        top: 5%;
        height: 90%;
    }
    80% {
        top: 95%;
        height: 0;
    }
}

@mixin abs-position {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
}

@mixin pseudo-base($child: false) {
    @include abs-position;

    content: '';
}

@mixin hidden {
    opacity: 0;
    visibility: hidden;
    transition: .5s;
}

@mixin visible {
    opacity: 1;
    visibility: visible;
}

@mixin parallax-bg {
    position: relative;
    background-size: cover;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

@mixin with-child($child, $delay: 0, $additional: '') {
    @if not $child {
        &#{$additional} {
            @content;

            transition-delay: #{$delay}s;
        }
    } @else {
        &#{$additional} #{$child} {
            @for $i from 0 through 10 {
                &:nth-child(#{$i + 1}) {
                    @content;

                    transition-delay: #{$delay + .5 * $i}s;
                }
            }
        }
    }
}

@mixin scale-on-hover($child: false) {
    transition: .3s;

    &:hover {
        transform: scale(1.1);
    }
}

@mixin fade-up($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        @include hidden;

        transform: translateY(2.5rem);
        @content;
    }

    @include with-child($child, $delay, '.active') {
        @include visible;

        transform: translate(0);
    }
}

@mixin fade-left($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        @include hidden;

        transform: translateX(2.5rem);
        @content;
    }

    @include with-child($child, $delay, '.active') {
        @include visible;

        transform: translate(0);
    }
}

@mixin fade-right($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        @include hidden;

        transform: translateX(-2.5rem);
        @content;
    }

    @include with-child($child, $delay, '.active') {
        @include visible;

        transform: translate(0);
    }
}

@mixin slide-in-left($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        & > * {
            transform: translateX(100%);
            transition: 1s;
            transition-delay: #{$delay}s;

            @content;
        }
    }

    @include with-child($child, $delay, '.active') {
        & > * {
            transform: translate(0);
        }
    }
}

@mixin slide-in-right($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        & > * {
            transform: translateX(-100%);
            transition: 1s;
            transition-delay: #{$delay}s;

            @content;
        }
    }

    @include with-child($child, $delay, '.active') {
        & > * {
            transform: translate(0);
        }
    }
}

@mixin slide-in-up($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        & > * {
            transform: translateY(100%);
            transition: 1s;
            transition-delay: #{$delay}s;

            @content;
        }
    }

    @include with-child($child, $delay, '.active') {
        & > * {
            transform: translate(0);
        }
    }
}

@mixin slide-in-down($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        & > * {
            transform: translateY(-100%);
            transition: 1s;
            transition-delay: #{$delay}s;

            @content;
        }
    }

    @include with-child($child, $delay, '.active') {
        & > * {
            transform: translate(0);
        }
    }
}

@mixin slide-in-rotate-left($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        transform: rotateY(90deg);
        transform-origin: right;
        transition: 1s linear;

        @content;
    }

    @include with-child($child, $delay, '.active') {
        transform: rotate(0);
    }
}

@mixin slide-in-rotate-right($delay: 0, $child: false) {
    @include with-child($child, $delay) {
        transform: rotateY(-90deg);
        transform-origin: left;
        transition: 1s linear;

        @content;
    }

    @include with-child($child, $delay, '.active') {
        transform: rotate(0);
    }
}
