@import 'base';
@import 'nav';
@import 'footer';

main {
    padding-top: 4.7rem;
}

.container {
    position: relative;
    width: 65%;
    margin: auto;
    padding: 5rem 0;
}
