@import 'mixins';

footer {
    background: #000;
    color: #fff;

    .container {
        a, 
        p {
            display: block;
            margin: .5rem 0;
            font-size: 1.2rem;

            img {
                width: 3.5rem;
            }
        }

        & > div:not(.map) {
            display: flex;
            align-items: flex-start;

            & > img {
                width: 3rem;
                height: 3rem;
                margin-right: .5rem;
            }
        }

        .map {
            background: #fff;
            z-index: 1;
        }
    }
}
