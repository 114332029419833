@import 'mixins';

nav {
    position: fixed;
    width: 100%;
    background: #000;
    z-index: 10;

    .container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: .5rem 0;

        .logo img {
            display: block;
            width: 8rem;
        }

        .menu {
            display: flex;
            justify-content: center;
            padding: 0;

            a {
                position: relative;
                padding: 1.2rem 2rem;
                color: #fff;
                font-size: 1.1rem;
                text-transform: uppercase;
                transition: .3s;

                &:hover {
                    color: $main_color;
                }

                &:not(:last-child):after {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    margin: auto;
                    width: 2px;
                    height: 60%;
                    background: $main_color;
                    transform: rotateZ(15deg);
                }
            }
        }

        .phone {
            display: flex;
            align-items: center;
            font-size: 1.5rem;
            transition: .3s;

            &:hover {
                color: $main_color;
            }

            img {
                width: 2.5rem;
                margin-right: .5rem;
            }
        }

        .social-icons {
            display: flex;
            align-items: center;

            img {
                width: 2.5rem;
                margin: 0 .5rem;
                transition: .3s;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}
