@import "base_pc";
@import "base_index";

main .logo {
    position: absolute;
    top: 6rem;
    left: 6rem;
    width: 9rem;
    z-index: 3;
}

main .container {
    display: flex;
    align-items: center;

    .description {
        width: 60%;

        h2 {
            font-size: 4rem;
        }
    }

    & > img {
        width: 40%;

        @include fade-left(1) {
            transform: translateX(5rem);
        }
    }
}

.brands {
    @include parallax-bg;

    height: 80vh;
    background-image: url(../img/brands_bg.jpg);

    .text {
        @include abs-position;

        left: auto;
        display: grid;
        width: 40%;
        align-items: center;
        padding: 5rem 3rem;
        text-align: center;

        h2 {
            font-weight: 400;
            
            span {
                display: block;
                margin-top: 1rem;
                font-size: 2rem;
                font-weight: 300;
            }
        }

        img {
            width: 100%;
            margin: 3rem 0;
        }
    }
}

.services {
    h2 {
        margin: 4rem;
        font-size: 4rem;
    }

    .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);

        .item {
            &:nth-child(1) {
                @include slide-in-up(.3);
            }

            &:nth-child(2) {
                @include slide-in-rotate-left(.8);
            }

            &:nth-child(3) {
                @include slide-in-right;
            }

            &:nth-child(4) {
                @include slide-in-down(.5);
            }

            &:nth-child(5) {
                @include slide-in-left(.5);
            }

            &:nth-child(6) {
                @include slide-in-down(.8);
            }

            &:nth-child(7) {
                @include slide-in-rotate-right;
            }

            &:nth-child(8) {
                @include slide-in-up(.3);
            }
        }
    }
}

.advantages .description {
    margin-left: 3rem;
}

.team {
    @include parallax-bg;

    background-image: url(../img/team_bg.jpg);

    .container {
        display: block;

        h2 {
            font-size: 4rem;
        }

        .grid {
            grid-template-columns: repeat(6, 1fr);
            margin-top: 5rem;

            a:hover img {
                transform: scale(1.1);
            }
        }
    }
}
