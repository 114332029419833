@import 'mixins';

.video {
    position: relative;
    width: 100%;
    margin: 0;

    video {
        width: 100%;
    }

    img {
        @include abs-position;

        width: 100%;
        height: 100%;

        &:last-child {
            @include scale-on-hover;

            width: 6rem;
            height: auto;
            cursor: pointer;
        }
    }

    &.active img {
        @include hidden;
    }
}

main .container {
    h2 {
        @include fade-up;

        font-weight: 300;
    }

    ul {
        margin-left: 1rem;
        list-style: disc;

        li {
            margin: .3rem 0;

            &::marker {
                color: $main_color;
            }
        }
    }

    .text {
        position: relative;
        padding: 1rem 0 1rem 4.5rem;

        @include fade-up($child: 'p');
        @include fade-up(.5, 'li');

        &:before {
            content: '';
            position: absolute;
            top: 5%;
            right: auto;
            bottom: auto;
            left: 1.7rem;
            width: 2px;
            height: 0;
            background: $main_color;
        }

        &.active:before {
            animation: vertical-line-slide-90 2s linear infinite;
        }
    }
}

.brands {
    @include parallax-bg;

    height: 80vh;
    background-image: url(../img/brands_bg.jpg);

    .text {
        @include abs-position;

        left: auto;
        display: grid;
        width: 40%;
        align-items: center;
        padding: 5rem 3rem;
        text-align: center;

        h2 {
            font-weight: 400;
            
            span {
                display: block;
                margin-top: 1rem;
                font-size: 2rem;
                font-weight: 300;
            }
        }

        img {
            width: 100%;
            margin: 3rem 0;
        }
    }
}

.services {
    h2 {
        @include fade-up;

        text-align: center;
    }

    .item {
        position: relative;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
        }

        .description {
            @include abs-position;

            width: 80%;
            top: 10%;
            bottom: 0;
            padding: 0 10%;
            color: #fff;

            img {
                width: 6rem;
                margin: 0 -1rem 1rem;
            }

            h3 {
                font-size: 2.5rem;
            }

            p {
                font-size: 1.2rem;
            }

            .button {
                position: absolute;
                bottom: 15%;
            }
        }
    }
}

.advantages.container img {
    @include fade-right {
        transform: translateX(-5rem);
    };
}

.team {
    @include parallax-bg;

    .container {
        display: block;

        h2 {
            @include fade-up;

            color: #fff;
        }

        .button {
            margin: 1rem 0;
        }

        .grid {
            display: grid;
            grid-row-gap: 1.5rem;
            margin-top: 5rem;

            a {
                width: 80%;
                text-align: center;

                img {
                    width: 100%;
                    border-radius: 50%;
                    transition: .3s;
                }
            }
        }
    }
}
